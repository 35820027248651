export default [
  {
    header: "Venue Menu",
  },
  {
    title: "Venue Dashboard",
    route: "partners-dashboard",
    icon: "MailIcon",
  },
  {
    title: "Gateway / Hotspot",
    route: "partners-gateway",
    icon: "MailIcon",
  },
  {
    title: "Report",
    icon: "FileTextIcon",
    children: [
      {
        title: "Users",
        route: "venue-reports-users",
      },
      {
        title: "Impressions",
        route: "venue-reports-impressions",
      },
      {
        title: "Connections",
        route: "venue-reports-connections",
      },
      {
        title: "Traffic",
        route: "venue-reports-traffic",
      },
      {
        title: "People Analytics",
        route: "venue-reports-people",
      },
    ],
  },

  {
    title: "Admin Users",
    icon: "UserIcon",
    route: "apps-users-list",
  },
];
