export default [
 

   {
    title: 'Admin Dashboards',
        route: 'apps-reports-people',
    icon: 'HomeIcon',
  },
  
]
